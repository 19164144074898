import Cookies from 'js-cookie';
import cookie from 'cookie';

export default async function({ store, app, req }) {
    // Trigger cookie modal if no preferences have been stored.
    const key = 'cookiebar_dismissed';
    let hasDismissedCookies = false;
    if (process.server && req && req.headers && req.headers.cookie) {
        const parsedCookies = cookie.parse(req.headers.cookie);
        hasDismissedCookies = parsedCookies[key];
    } else {
        hasDismissedCookies = Cookies.get(key);
    }

    if (!hasDismissedCookies) {
        await store.dispatch('modal/open', 'cookie-modal');
    } else {
        // Explicitly dismiss the modal in case a soft-reload is triggered on a cached page
        await store.dispatch('modal/close', 'cookie-modal');
    }

    // Fetch everything on page load (server-side)
    if (process.server) {
        await store.dispatch('topics/fetchChannels');
    }

    // Fetch topics client-side if not filled already
    if (process.client && !store.state.topics.channels) {
        await store.dispatch('topics/fetchChannels');
    }
}
